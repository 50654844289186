.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.accordion-button {
  --bs-accordion-active-bg: white;
  --bs-accordion-active-color: black;
  --bs-accordion-btn-focus-border-color: red;
  
}

.accordion-button:focus {
  --bs-accordion-active-bg: lightgray;
  --bs-accordion-active-color: black;
  --bs-accordion-btn-focus-border-color: red;
  background-color: lightgray;
  
}

.accordion-button:not(.collapsed) {
  
  background-color: lightgray;
  --bs-accordion-active-color: black;
}

.accordion-button.collapsed {
  
  background-color: lightgray;
  --bs-accordion-active-color: black;
}

.eddm:hover {
  background-color: lightcoral;
  cursor: pointer;
}


.header-menu:hover {
  background-color: lightgray;
  -moz-border-radius-topleft: 0.25rem;
  -moz-border-radius-topright: 0.25rem;
  -moz-border-radius-bottomright: 0.25rem;
  -moz-border-radius-bottomleft: 0.25rem;
  -webkit-border-radius: 0.25rem 0.25rem 0.25rem 0.25rem;
  border-radius: 0.25rem 0.25rem 0.25rem 0.25rem;
}

.menu-item:hover {
  background-color: lightgray;
  -moz-border-radius-topleft: 0.25rem;
  -moz-border-radius-topright: 0.25rem;
  -moz-border-radius-bottomright: 0.25rem;
  -moz-border-radius-bottomleft: 0.25rem;
  -webkit-border-radius: 0.25rem 0.25rem 0.25rem 0.25rem;
  border-radius: 0.25rem 0.25rem 0.25rem 0.25rem;
}

.reactEasyCrop_Container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  user-select: none;
  touch-action: none;
  cursor: move;
  display: flex;
  justify-content: center;
  align-items: center;
}

.reactEasyCrop_Image,
.reactEasyCrop_Video {
  will-change: transform; /* this improves performances and prevent painting issues on iOS Chrome */
}

.reactEasyCrop_Contain {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.reactEasyCrop_Cover_Horizontal {
  width: 100%;
  height: auto;
}
.reactEasyCrop_Cover_Vertical {
  width: auto;
  height: 100%;
}

.reactEasyCrop_CropArea {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  box-shadow: 0 0 0 9999em;
  color: rgba(0, 0, 0, 0.5);
  overflow: hidden;
}

.reactEasyCrop_CropAreaRound {
  border-radius: 50%;
}

.reactEasyCrop_CropAreaGrid::before {
  content: ' ';
  box-sizing: border-box;
  position: absolute;
  border: 1px solid rgba(255, 255, 255, 0.5);
  top: 0;
  bottom: 0;
  left: 33.33%;
  right: 33.33%;
  border-top: 0;
  border-bottom: 0;
}

.reactEasyCrop_CropAreaGrid::after {
  content: ' ';
  box-sizing: border-box;
  position: absolute;
  border: 1px solid rgba(255, 255, 255, 0.5);
  top: 33.33%;
  bottom: 33.33%;
  left: 0;
  right: 0;
  border-left: 0;
  border-right: 0;
}